@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
}

html, body, #root, #root > div {
  height: 100%
}

.MuiCalendarPicker-root .Mui-selected {
  background-color: red !important;
}

.MuiSlider-root {
  width: 200px !important;
}

svg.wheel {
  transition: transform 0.4s ease-in-out;
}

svg text {
  font-size: 22px;
  font-family: 'Roboto';
}

.MuiDialogContent-root div {
  font-family: 'Roboto';
}

.MuiLinearProgress-root {
  background-color: #E5E5E5;
}

.MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #000000;
}

button {
  text-transform: none !important;
}

a {
  display: flex;
  color: black;
  align-items: center;
  text-decoration: none;
}

a span {
  position: relative;
  top: 1px;
  font-family: 'Roboto';
}

span {
  text-transform: none !important;
}

.fillVertical {
  max-height: 100vh;
}

.logo {
  width: 40px;
}

.MuiSelect-select {
  font-size: 12px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.MuiMenuItem-root {
  font-size: 12px !important;
}